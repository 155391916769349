import type { BaseKeyFunction } from "../../../../lib";

export type KeyCommunityMemberProps = {
  communityId: number;
  userId: number;
};

export const keyCommunityMember: BaseKeyFunction<KeyCommunityMemberProps> = ({
  communityId,
  userId,
}) => [`communities-member`, { communityId, userId }];
