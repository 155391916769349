"use client";

import { campaignDetailsSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCampaignDetails } from "./keyCampaignDetails";

export type UseRequestCampaignDetailsProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestCampaignDetails = ({
  id,
  ...props
}: UseRequestCampaignDetailsProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: campaignDetailsSchema,
      urlBackendPath: `/campaign-details/${id}`,
    },
    queryKey: keyCampaignDetails({ id }),
  }),
});
