import { z } from "zod";

import { guideSchema } from "../guideSchema";
import { radioStationSchema } from "./radioStationSchema";

export const radioStationDetailsSchema = z.object({
  radio_station: radioStationSchema,
  artists: guideSchema.array(),
});

export type RadioStationDetails = z.infer<typeof radioStationDetailsSchema>;
