import type { BaseKeyFunction } from "../../../../../../lib";

export type KeyCommunitiesStatsTotalsOverTimeProps = {
  endDate?: Date;
  id: number;
  startDate?: Date;
};

export const keyCommunitiesStatsTotalsOverTime: BaseKeyFunction<
  KeyCommunitiesStatsTotalsOverTimeProps
> = ({ endDate, id, startDate }) => [
  "communities-stats-totals-over-time",
  id?.toString(),
  startDate?.toString(),
  endDate?.toString(),
];
