"use client";

import { usePathMatch } from "../lib";

export const useActiveSidebarTab = ({
  baseUrl,
  tabs,
}: {
  baseUrl: string;
  tabs: string[];
}) => {
  const matcher = usePathMatch();
  return tabs.find((tab) => matcher(`${baseUrl}/${tab}`));
};
