import { z } from "zod";

import { imagesSchema } from "./imagesSchema";

export const transcriptSchema = z.object({
  transcript: z.string().nullable(),
  title: z.string().nullable(),
  images: imagesSchema.nullable(),
});

export type Transcript = z.infer<typeof transcriptSchema>;
