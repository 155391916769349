"use client";

import type {
  QueueContentType,
  QueuePosition,
  queueSchema,
} from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";

export type UseRequestQueueItemsProps = UseRequestProps;

export const useRequestQueueItems = ({
  ...props
}: UseRequestQueueItemsProps = {}) => ({
  mutatePost: useRequestMutation<
    typeof queueSchema,
    {
      content: {
        content_id: number;
        content_type: QueueContentType;
      };
      queue_position: QueuePosition;
    }
  >({
    ...props,
    mutationFnRequestProps: (data) => ({
      body: JSON.stringify(data),
      method: "POST",
      urlBackendPath: "/queue/items",
    }),
  }),
});
