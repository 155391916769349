"use client";

import { transcriptSchema } from "../../../lib";
import { useRequestQuery, type UseRequestQueryProps } from "../../../request";
import { keyTranscript } from "./keyTranscript";

export type UseRequestTranscriptProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestTranscript = ({
  id,
  ...props
}: UseRequestTranscriptProps) => ({
  query: useRequestQuery({
    enabled: typeof id === "number",
    ...props,
    queryFnRequestProps: {
      schema: transcriptSchema,
      urlBackendPath: `/audio/${id}/transcript`,
      next: {
        revalidate: 3600,
      },
    },
    queryKey: keyTranscript({ id }),
  }),
});
