import { z } from "zod";

export const getOptionsWithFallback = (
  baseOptions: Array<{ value: string; label: string }>,
  currentValue?: string,
): Array<{ value: string; label: string }> => {
  if (!currentValue) return baseOptions;

  const valueExists = baseOptions.some((opt) => opt.value === currentValue);
  if (valueExists) return baseOptions;

  return [
    ...baseOptions,
    {
      value: currentValue,
      label: currentValue,
    },
  ];
};

export const zodRequiredString = (message: string) =>
  z.string({ required_error: message }).min(1, { message });
