import { z } from "zod";

export enum ReferralStatus {
  ACCEPTED,
  SUBSCRIBED,
}

export const referralsSchema = z.object({
  code: z.string(),
  rank: z.number(),
  referrals_count: z.number(),
  users: z
    .object({
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
      email: z.string().email().nullable(),
      state: z.nativeEnum(ReferralStatus),
    })
    .array(),
  top: z
    .object({
      id: z.number(),
      code: z.string().optional().nullable(),
      rank: z.number(),
      referrals_count: z.number(),
      first_name: z.string().optional().nullable(),
      last_name: z.string().optional().nullable(),
    })
    .array(),
});

export type Referrals = z.infer<typeof referralsSchema>;
