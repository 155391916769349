import type { CookieKey } from "./types";
import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const COOKIE_KEY_AJS_ANONYMOUS_ID: CookieKey = "ajs_anonymous_id";

export type DeleteCookieAjsAnonymousIdProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieAjsAnonymousId = ({
  ...props
}: DeleteCookieAjsAnonymousIdProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_AJS_ANONYMOUS_ID, ...props });

export type GetCookieAjsAnonymousIdProps = Omit<GetCookieProps, "key">;

export const getCookieAjsAnonymousId = ({
  ...props
}: GetCookieAjsAnonymousIdProps = {}) =>
  getCookie({ key: COOKIE_KEY_AJS_ANONYMOUS_ID, ...props });

export type SetCookieAjsAnonymousIdProps = Omit<SetCookieProps, "key">;

export const setCookieAjsAnonymousId = ({
  ...props
}: SetCookieAjsAnonymousIdProps) =>
  setCookie({ key: COOKIE_KEY_AJS_ANONYMOUS_ID, ...props });
