"use client";

import type { UseQueryResult } from "@tanstack/react-query";

import type { RadioStationDetails } from "../../../lib";
import { radioStationDetailsSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyRadioStation } from "./keyRadioStation";

export type UseRequestRadioStationProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestRadioStation = ({
  id,
  ...props
}: UseRequestRadioStationProps) => ({
  query: useRequestQuery({
    ...props,
    enabledWithoutSessionToken: false,
    queryKey: keyRadioStation({ id }),
    queryFnRequestProps: {
      schema: radioStationDetailsSchema,
      urlBackendPath: `/radio-station-details/${id}`,
    },
  }) as UseQueryResult<RadioStationDetails>,
});
