"use client";

import type { UseRequestProps } from "../../../request";
import { useRequest } from "../../../request";

export type UseRequestSessionProps = UseRequestProps;

export const useRequestSession = ({
  ...props
}: UseRequestSessionProps = {}) => ({
  requestPut: useRequest({
    ...props,
    fnRequestProps: (data: {
      audio_id: number;
      current_position: number;
      duration: number;
      content_id: number;
      content_type: "prayer" | "radio_song";
      id: string;
    }) => {
      const { duration, id, ...body } = data;

      return {
        body: JSON.stringify({
          ...body,
          duration: duration > 0 ? duration : undefined,
        }),
        method: "PUT",
        urlBackendPath: `/sessions/${id}`,
      };
    },
  }),
});
