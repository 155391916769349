"use client";

import { queueSkipsSchema } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestMutation, useRequestQuery } from "../../../../request";
import { keyQueueSkips } from "./keyQueueSkips";

export type UseRequestQueueSkipsProps = UseRequestProps;

export const useRequestQueueSkips = ({
  ...props
}: UseRequestQueueSkipsProps = {}) => ({
  mutatePost: useRequestMutation<typeof queueSkipsSchema>({
    ...props,
    mutationFnRequestProps: () => ({
      method: "POST",
      schema: queueSkipsSchema,
      urlBackendPath: "/queue/skips",
    }),
  }),
  query: useRequestQuery({
    enabledWithoutSessionToken: false,
    queryKey: keyQueueSkips(),
    queryFnRequestProps: {
      schema: queueSkipsSchema,
      urlBackendPath: "/queue/skips",
    },
  }),
});
