"use client";

import type { Locale } from "@packages/sdk";
import { useRequestMe } from "@packages/sdk";
import { useSearchParams } from "next/navigation";
import { NextIntlClientProvider } from "next-intl";
import type { ComponentProps } from "react";
import { useEffect } from "react";

import { usePathname, useRouter } from "../utils";

export type I18nProviderClientProps = ComponentProps<
  typeof NextIntlClientProvider
>;

export const I18nProviderClient = ({
  children,
  locale,
  ...props
}: I18nProviderClientProps) => {
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  const { query: queryMe } = useRequestMe();

  useEffect(() => {
    if (queryMe.data) {
      const meLocale = queryMe.data?.language;
      if (meLocale && meLocale !== locale)
        router.push(
          `${pathname}${searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""}`,
          { locale: meLocale as Locale },
        );
    }
  }, [queryMe.data?.language]);

  return (
    <NextIntlClientProvider locale={locale} timeZone="UTC" {...props}>
      {children}
    </NextIntlClientProvider>
  );
};
