export type getURLFromDeeplinkProps = {
  deeplink: string;
  type?: "path" | "showcase";
};

export const getURLFromDeeplink = ({
  deeplink,
  type = "path",
}: getURLFromDeeplinkProps): string => {
  if (!deeplink) return null;
  let pieces;
  if (!deeplink.startsWith("hallow")) {
    if (!deeplink.startsWith("http")) return null;
    pieces = deeplink.split("hallow.com/");
    if (pieces.length < 2) return deeplink; // some external URL
  } else {
    pieces = deeplink.split("hallow://");
    if (pieces.length < 2) return null;
  }

  if (type === "path") return `/${pieces[1]}`;

  const linkPieces = pieces[1].split("/");
  return `/${linkPieces[0]}/${linkPieces[1]}`;
};
