import { z } from "zod";

import { challengeSchema, defaultCollectionSchema } from "./collectionSchema";

export const sectionItemCollectionSchema = z.discriminatedUnion("type", [
  defaultCollectionSchema.omit({
    prayers: true,
  }),
  challengeSchema.omit({
    prayers: true,
  }),
]);

export type SectionItemCollection = z.infer<typeof sectionItemCollectionSchema>;

export const sectionItemChallengeSchema = challengeSchema.omit({
  prayers: true,
});

export type SectionItemChallenge = z.infer<typeof sectionItemChallengeSchema>;
