"use client";

import type { UseRequestProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunityUser } from "./keyCommunityUser";
import {
  getRequestCommunityUserProps,
  type RequestCommunityUserParams,
} from "./requestCommunityUser";

export type UseRequestCommunityUserProps = UseRequestProps &
  RequestCommunityUserParams;

export const useRequestCommunityUser = ({
  id,
  ...props
}: UseRequestCommunityUserProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: getRequestCommunityUserProps({ id }),
    queryKey: keyCommunityUser({ id }),
  }),
});
