export function getRequestNextPageParam({
  total,
  lastPageParam,
  pageSize,
}: {
  total: number;
  lastPageParam: number;
  pageSize: number;
}): number | null {
  const hasMorePages = total > (lastPageParam + 1) * pageSize;

  return hasMorePages ? lastPageParam + 1 : null;
}
