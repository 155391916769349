"use client";

import { userSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunityMember } from "./keyCommunityMember";

export type UseRequestCommunityMemberProps = UseRequestQueryProps & {
  communityId: number;
  userId: number;
};

export const useRequestCommunityMember = ({
  communityId,
  userId,
  ...props
}: UseRequestCommunityMemberProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: () => ({
      schema: userSchema,
      urlBackendPath: `/communities/${communityId}/members/${userId}`,
    }),
    queryKey: keyCommunityMember({ communityId, userId }),
  }),
});
