"use client";

import { z } from "zod";

import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyExists } from "./keyExists";

export type UseRequestExistsProps = UseRequestQueryProps & {
  email: string;
};

export const useRequestExists = ({
  email,
  enabled,
  ...props
}: UseRequestExistsProps) => ({
  ...props,
  query: useRequestQuery({
    enabled: enabled && email?.length > 0,
    enabledWithoutSessionToken: true,
    queryFnRequestProps: {
      body: JSON.stringify({ email }),
      method: "POST",
      requestType: "default",
      schema: z.boolean(),
      urlBackendPath: "/exists",
    },
    queryKey: keyExists({ email }),
  }),
});
