"use client";

import { communitiesAdminMostPopularSchema } from "../../../../../../lib";
import type { UseRequestQueryProps } from "../../../../../../request";
import { useRequestQuery } from "../../../../../../request";
import { keyCommunitiesMostPopular } from "./keyCommunitiesMostPopular";

export type UseRequestCommunitiesMostPopularProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestCommunitiesMostPopular = ({
  id,
  ...props
}: UseRequestCommunitiesMostPopularProps) => ({
  ...props,
  query: useRequestQuery({
    queryFnRequestProps: {
      schema: communitiesAdminMostPopularSchema,
      urlBackendPath: `/communities/${id}/stats/most-popular`,
    },
    queryKey: keyCommunitiesMostPopular({ id }),
  }),
});
