import { z } from "zod";

import type { CookieKey } from "./types";
import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const cookieHlwConsentSchema = z.number().array().nullable();

export type CookieHlwConsent = z.TypeOf<typeof cookieHlwConsentSchema>;

export const COOKIE_KEY_HLW_CONSENT: CookieKey = "hlwconsent";

export type DeleteCookieHlwConsentProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieHlwConsent = ({
  ...props
}: DeleteCookieHlwConsentProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_HLW_CONSENT, ...props });

export type GetCookieHlwConsentProps = Omit<GetCookieProps, "key">;

export const getCookieHlwConsent = ({
  ...props
}: GetCookieHlwConsentProps = {}) =>
  getCookie({
    key: COOKIE_KEY_HLW_CONSENT,
    schema: cookieHlwConsentSchema,
    ...props,
  });

export type SetCookieHlwConsentProps = Omit<SetCookieProps, "key">;

export const setCookieHlwConsent = ({ ...props }: SetCookieHlwConsentProps) =>
  setCookie({ key: COOKIE_KEY_HLW_CONSENT, ...props });
