"use client";

import { prayerQueueItemSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyBackgroundSounds } from "./keyBackgroundSounds";

export type UseRequestQueryBackgroundSoundsProps = UseRequestQueryProps;

export const useRequestBackgroundSounds = ({
  ...props
}: UseRequestQueryBackgroundSoundsProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: prayerQueueItemSchema.array(),
      urlBackendPath: "/background-tracks",
      cache: "force-cache",
      next: {
        revalidate: 86400,
        tags: ["background-sounds"],
      },
    },
    queryKey: keyBackgroundSounds(),
    enabledWithoutSessionToken: false,
    gcTime: 86400000,
  }),
});
