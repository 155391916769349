import { userSchema } from "../../../../lib";
import type { RequestProps } from "../../../../request";
import { request } from "../../../../request";

export type RequestCommunityUserParams = { id: number };

export type RequestCommunityUserProps = Omit<RequestProps, "url"> &
  RequestCommunityUserParams;

export const getRequestCommunityUserProps = ({
  id,
}: RequestCommunityUserParams) => ({
  schema: userSchema,
  urlBackendPath: `/community/users/${id}`,
});

export const requestCommunityUser = ({
  id,
  ...props
}: RequestCommunityUserProps) =>
  request({
    ...props,
    ...getRequestCommunityUserProps({ id }),
  });
