"use client";

import type { Dispatch, SetStateAction } from "react";
import { createContext } from "react";

import type { OauthRefresh } from "../types";
import type { SessionStatus } from "./types";

export type SessionContext = {
  hasRefreshToken: boolean;
  logout: ({ callback }: { callback?: () => any }) => void;
  refresh: ({
    callback,
  }?: {
    callback?: (data: OauthRefresh) => any;
  }) => Promise<OauthRefresh>;
  setStatus: Dispatch<SetStateAction<SessionStatus>>;
  setToken: Dispatch<SetStateAction<null | string>>;
  status: SessionStatus;
  token: null | string;
};

export const sessionContext = createContext<SessionContext>({
  hasRefreshToken: false,
  logout: () => {},
  refresh: () => Promise.resolve({}),
  setStatus: () => {},
  setToken: () => {},
  status: "loading",
  token: null,
});
