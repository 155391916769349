"use client";

import { cartSchema } from "../../../lib/types/schema/subscription/cartSchema";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyCart } from "./keyCart";

export type CartProps = {
  priceId?: string;
  promoCode?: string;
};

export const useRequestCart = ({ priceId, promoCode }: CartProps) => ({
  query: useRequestQuery({
    enabled: typeof priceId === "string",
    queryFnRequestProps: {
      schema: cartSchema,
      urlBackendPath: `/cart?price_id=${priceId ?? ""}${promoCode ? `&promo_code=${promoCode}` : ""}`,
    },
    queryKey: keyCart(),
  }),
  checkCart: useRequestMutation({
    mutationFnRequestProps: ({ promoCode }: Pick<CartProps, "promoCode">) => ({
      schema: cartSchema,
      urlBackendPath: `/cart?price_id=${priceId ?? ""}${promoCode ? `&promo_code=${promoCode}` : ""}`,
      method: "GET",
    }),
  }),
});
