import { z } from "zod";

import { sectionSchema } from "./sectionSchema";

export const homeSchema = z.object({
  sections: sectionSchema.array(),
  metadata: z
    .object({
      page_id: z.number(),
      is_night: z.boolean(),
    })
    .optional(),
});

export type Home = z.infer<typeof homeSchema>;
