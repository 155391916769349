import { pagesSchema } from "../../../lib";
import { request } from "../../../request";

export type RequestPagesTrustedProps = {
  page: string;
};

export const requestPagesTrusted = ({ page }: RequestPagesTrustedProps) =>
  request({
    next: { tags: [`pages`, `pages-${page}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: pagesSchema,
    urlBackendPath: `/trusted/pages/${page}`,
  });
