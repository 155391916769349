"use client";

import type { CommunityMembersSchema } from "../../../../lib";
import {
  communityMembersSchema,
  getRequestNextPageParam,
} from "../../../../lib";
import type { UseRequestInfiniteQueryProps } from "../../../../request";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunityMembers } from "./keyCommunityMembers";

export type UseRequestCommunityMembersProps = UseRequestInfiniteQueryProps & {
  id: number;
};

export const useRequestCommunityMembers = ({
  id,
  ...props
}: UseRequestCommunityMembersProps) => ({
  infiniteQuery: useRequestInfiniteQuery({
    getNextPageParam: (
      lastPage: CommunityMembersSchema,
      _allPages: CommunityMembersSchema[],
      lastPageParam: number,
    ) =>
      getRequestNextPageParam({
        total: lastPage.total,
        lastPageParam,
        pageSize: 50,
      }),
    initialPageParam: 0,
    ...props,
    queryFnRequestProps: ({ pageParam }) => ({
      schema: communityMembersSchema,
      urlBackendPath: `/communities/${id}/members?page=${pageParam}`,
    }),
    queryKey: keyCommunityMembers({ id }),
  }),
});
