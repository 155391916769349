import { z } from "zod";

export const communitiesAdminGraphSchema = z.object({
  data: z
    .object({
      label: z.string(),
      tick: z.string().nullable().optional(),
      value: z.number(),
    })
    .array(),
  total: z.number(),
});

export type CommunitiesAdminGraph = z.infer<typeof communitiesAdminGraphSchema>;
