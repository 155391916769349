import { communitySchema } from "../../../../lib";
import { request } from "../../../../request";

export const requestCommunitiesAdminCommunityProps = {
  schema: communitySchema,
};

export type RequestCommunitiesAdminCommunityProps = {
  id: number;
};

export const requestCommunitiesAdminCommunity = ({ id, ...props }) =>
  request({
    ...props,
    urlBackendPath: `/communities/${id}`,
    ...requestCommunitiesAdminCommunityProps,
  });
