"use client";

import { setCookieHasRefreshToken } from "../../../lib";
import { useRequestMutation } from "../../../request";
import { useSession } from "../../../session";
import { loginSchema } from "./types";

export const useRequestLogin = () => {
  const { setStatus } = useSession();

  return {
    mutationPostEmailPassword: useRequestMutation({
      mutationFnRequestProps: (data: { email: string; password: string }) => ({
        body: JSON.stringify(data),
        requestType: "default",
        schema: loginSchema,
        urlBackendPath: "/login",
      }),
      onSuccess: () => {
        setCookieHasRefreshToken({ value: true });
        setStatus("authenticated");
      },
    }),
  };
};
