"use client";

import { queueSchema } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";

export type UseRequestQueueNextItemsProps = UseRequestProps;

export const useRequestQueueNextItems = ({
  ...props
}: UseRequestQueueNextItemsProps = {}) => ({
  mutatePost: useRequestMutation<typeof queueSchema>({
    ...props,
    mutationFnRequestProps: () => ({
      method: "POST",
      schema: queueSchema,
      urlBackendPath: "/queue/next-items",
    }),
  }),
});
