import { z } from "zod";

import { communityActivityFeedResultSchema } from "./communityActivityFeedResultSchema";

export const communityActivityFeedSchema = z.object({
  results: z.array(communityActivityFeedResultSchema),
});

export type CommunityActivityFeed = z.TypeOf<
  typeof communityActivityFeedSchema
>;
