import { z } from "zod";

import { imagesSchema } from "../imagesSchema";

export const radioStationSchema = z.object({
  id: z.number(),
  name: z.string(),
  short_desc: z.string(),
  desc: z.string(),
  images: imagesSchema,
  has_access: z.boolean(),
});

export type RadioStation = z.infer<typeof radioStationSchema>;
