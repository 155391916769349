import { z } from "zod";

export const communityActivityFeedReferenceTypeEnum = z.enum([
  "intention",
  "prompt_response",
]);

export type CommunityActivityFeedReferenceType = z.TypeOf<
  typeof communityActivityFeedReferenceTypeEnum
>;
