"use client";

import { promoCodeSchema } from "../../../lib/types/schema/subscription/cartSchema";
import { useRequestQuery } from "../../../request";
import { keyPromoCode } from "./keyPromoCode";

export const useRequestPromo = ({ code }: { code: string }) => ({
  query: useRequestQuery({
    queryFnRequestProps: {
      schema: promoCodeSchema,
      urlBackendPath: `/promocodes/${code}`,
    },
    queryKey: keyPromoCode(),
  }),
});
