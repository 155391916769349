"use client";

import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";

import type { CommunityUpdateDetails } from "../../../../lib";
import { communitySchema } from "../../../../lib/types/schema/community/communitySchema";
import { useRequestMutation, useRequestQuery } from "../../../../request";
import { keyCommunitiesChildren } from "../children";
import { keyCommunitiesAdminCommunities } from "./keyCommunitiesAdminCommunities";
import { keyCommunitiesAdminCommunity } from "./keyCommunitiesAdminCommunity";
import { requestCommunitiesAdminCommunityProps } from "./requestCommunitiesAdminCommunity";

export type UseRequestCommunitiesAdminCommunityProps = {
  id: number;
};

export const useRequestCommunitiesAdminCommunity = ({
  id,
}: UseRequestCommunitiesAdminCommunityProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationPut: useRequestMutation({
      mutationFnRequestProps: (data: CommunityUpdateDetails) => ({
        body: JSON.stringify(data),
        method: "PUT",
        schema: communitySchema,
        urlBackendPath: `/me/communities/${id}`,
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesAdminCommunity({ id }), locale],
        });
        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesAdminCommunities(), locale],
        });
      },
    }),
    mutationUnlink: useRequestMutation({
      mutationFnRequestProps: (data: { id: number }) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: `/communities/${data.id}/unlink`,
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesChildren({ id }), locale],
        });
      },
    }),
    query: useRequestQuery({
      queryFnRequestProps: {
        urlBackendPath: `/communities/${id}`,
        ...requestCommunitiesAdminCommunityProps,
      },
      queryKey: keyCommunitiesAdminCommunity({ id }),
    }),
  };
};
