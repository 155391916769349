import Cookies from "js-cookie";
import { z } from "zod";

import { HallowCookies } from "../constants";

export const getValidatedCookie = <SchemaType>(
  key: string,
  schema: z.ZodSchema<SchemaType>,
  isJson: boolean = true,
): SchemaType | null => {
  const rawCookie = Cookies.get(key);
  if (!rawCookie) return null;

  try {
    let parsedCookie = rawCookie;
    if (isJson) parsedCookie = JSON.parse(rawCookie);
    const result = schema.parse(parsedCookie);
    return result;
  } catch (error) {
    console.error(`Failed to validate ${key} cookie:`, error);
    return null;
  }
};

const ConsentSchema = z.array(z.number());

export const getValidatedCookiesHlwConsent = () => {
  return getValidatedCookie(HallowCookies.CONSENT, ConsentSchema);
};

export const getValidatedCookiesHighPrivacyZone = () => {
  return getValidatedCookie("hlwhighprivacy", z.boolean());
};

export const setCookiesHighPrivacyZone = (value: string) => {
  Cookies.withConverter({
    write: function (value) {
      return value;
    },
  }).set("hlwhighprivacy", value);
};
