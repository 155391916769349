import { communityChallengeDetailsSchema } from "../../../../lib/types/schema/community/challenge/communityChallengeDetailsSchema";
import type { RequestProps } from "../../../../request";
import { request } from "../../../../request";

export type RequestCommunityChallengeUsersProps = Omit<RequestProps, "url"> & {
  id: number;
};

export const requestCommunityChallengeUsers = ({
  id,
  ...props
}: RequestCommunityChallengeUsersProps) =>
  request({
    ...props,
    requestType: "authenticated",
    schema: communityChallengeDetailsSchema,
    urlBackendPath: `/community-challenges/${id}/users`,
  });
