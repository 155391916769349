import { z } from "zod";

import { imagesSchema } from "./imagesSchema";

export const guideSchema = z.object({
  deeplink: z.string().nullable(),
  desc: z.string(),
  id: z.number(),
  image_url: z.string().url(),
  images: imagesSchema,
  locales: z.array(z.string()).optional().nullable(),
  name: z.string(),
  sample_audio_url: z.string().url().nullable(),
  tagline: z.string(),
});

export type Guide = z.infer<typeof guideSchema>;
