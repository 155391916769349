import { z } from "zod";

import { prayerSchema } from "../../prayer";
import { userSchema } from "../../user";

export const communityActivityFeedResultReferenceSchema = z.union([
  z.object({
    gradient_id: z.number().optional(),
    text: z.string(),
  }),
  z.object({
    prayer: prayerSchema,
    text: z.string(),
    user: userSchema,
  }),
]);

export type CommunityActivityFeedResultReference = z.TypeOf<
  typeof communityActivityFeedResultReferenceSchema
>;
