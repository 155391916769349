"use client";

import { productsSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyPlans } from "./keyProducts";

export const useRequestProducts = () => {
  return {
    query: useRequestQuery({
      queryFnRequestProps: {
        schema: productsSchema,
        urlBackendPath: "/products/plans",
      },
      queryKey: keyPlans(),
    }),
  };
};
