"use client";

import { useEffect, useState } from "react";

import type { CookieSchema } from "../lib";
import { getCookie, setCookie } from "../lib";

export type UseCookieProps<T = any> = {
  defaultValue?: T;
  key: string;
  schema?: CookieSchema;
};

export const useCookie = <T = any>({
  defaultValue = null,
  key,
  schema,
}: UseCookieProps<T>) => {
  const [state, setState] = useState<null | T>(
    getCookie({ key, schema }) ?? defaultValue,
  );

  useEffect(() => {
    setCookie({ key, value: state });
  }, [state]);

  return [state, setState] as const;
};
