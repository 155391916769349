"use client";

import type { genericBooleanSchema } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";

export type UseRequestQueueCurrentItemIndexProps = UseRequestProps;

export const useRequestQueueCurrentItemIndex = ({
  ...props
}: UseRequestQueueCurrentItemIndexProps = {}) => ({
  mutatePut: useRequestMutation<
    typeof genericBooleanSchema,
    { current_item_index: number }
  >({
    mutationFnRequestProps: (data) => ({
      body: JSON.stringify(data),
      method: "PUT",
      urlBackendPath: "/queue/current-item-index",
    }),
  }),
});
