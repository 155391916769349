import { z } from "zod";

import { playlistContentPreviewSchema } from "../../playlist/playlistContentPreviewSchema";
import { communityChallengeSchema } from "./communityChallengeSchema";

export const communityChallengeDetailsSchema = z.object({
  community_challenge: communityChallengeSchema,
  playlist_content: z.array(playlistContentPreviewSchema),
});

export type CommunityChallengeDetails = z.infer<
  typeof communityChallengeDetailsSchema
>;
