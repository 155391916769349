"use client";

import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";

import type { CommunityActivityFeedReferenceType } from "../../../../lib";
import type { UseRequestMutationProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";
import { keyCommunityActivityFeed } from "../activityFeed";
import { keyCommunitiesAdminFlaggable } from "./flaggable";

type useRequestCommunitiesAdminPostVariables = {
  communityPostId: number;
};

type useRequestCommunitiesAdminDeleteVariables = {
  communityPostId: number;
  type?: CommunityActivityFeedReferenceType;
};

export type useRequestCommunitiesAdminPostProps = {
  id: number;
  mutationDeleteProps?: UseRequestMutationProps<
    any,
    useRequestCommunitiesAdminDeleteVariables
  >;
  mutationResolveProps?: UseRequestMutationProps<
    any,
    useRequestCommunitiesAdminPostVariables
  >;
};

export const useRequestCommunitiesAdminPost = ({
  id,
  mutationDeleteProps,
  mutationResolveProps,
}: useRequestCommunitiesAdminPostProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationDelete: useRequestMutation({
      ...mutationDeleteProps,
      mutationFnRequestProps: ({ communityPostId }) => ({
        method: "DELETE",
        urlBackendPath: `/moderation/communities/${id}/posts/${communityPostId}`,
      }),
      onSuccess: (data, variables, context) => {
        const { type } = variables;

        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesAdminFlaggable({ id }), locale],
        });

        queryClient.invalidateQueries({
          queryKey: [keyCommunityActivityFeed({ id }), locale],
        });

        if (type) {
          queryClient.invalidateQueries({
            queryKey: [keyCommunityActivityFeed({ id, type }), locale],
          });
        }

        if (mutationDeleteProps?.onSuccess)
          mutationDeleteProps.onSuccess(data, variables, context);
      },
    }),
    mutationResolve: useRequestMutation({
      ...mutationResolveProps,
      mutationFnRequestProps: ({ communityPostId }) => ({
        urlBackendPath: `/moderation/communities/${id}/posts/${communityPostId}/mark-as-reviewed`,
      }),
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [keyCommunitiesAdminFlaggable({ id }), locale],
        });
        if (mutationResolveProps?.onSuccess)
          mutationResolveProps.onSuccess(data, variables, context);
      },
    }),
  };
};
