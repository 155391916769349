import type {
  BaseKeyFunction,
  CommunityActivityFeedReferenceType,
} from "../../../../lib";

export type KeyCommunityActivityFeedProps = {
  id: number;
  type?: CommunityActivityFeedReferenceType;
};

export const keyCommunityActivityFeed: BaseKeyFunction<
  KeyCommunityActivityFeedProps
> = ({ id, type }) => ["community-activity-feed", id.toString(), type ?? "all"];
