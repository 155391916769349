import { communitySchema } from "../../../lib";
import { request } from "../../../request";

export type RequestCommunityTrustedProps = {
  id: number;
};

export const requestCommunityTrusted = ({ id }: RequestCommunityTrustedProps) =>
  request({
    next: { tags: [`community`, `community-${id}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: communitySchema,
    urlBackendPath: `/trusted/communities/${id}`,
  });
