"use client";

import type { PaymentResult } from "../../../lib";
import { productsSchema } from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyGiftCards } from "./keyProducts";

export const useRequestGift = () => {
  return {
    query: useRequestQuery({
      queryFnRequestProps: {
        schema: productsSchema,
        urlBackendPath: "/products/gift-cards",
        requestType: "default",
      },
      enabledWithoutSessionToken: true,
      queryKey: keyGiftCards(),
    }),
    mutationPurchase: useRequestMutation({
      mutationFnRequestProps: (
        data: PaymentResult & { count: number; priceId: string },
      ) => ({
        body: JSON.stringify({
          count: data.count,
          payment_method: data.paymentMethod,
          payment_platform: data.paymentPlatform,
          price_id: data.priceId,
          email: data.email,
        }),
        method: "POST",
        urlBackendPath: "/gift",
        requestType: "default",
      }),
    }),
  };
};
