import { z } from "zod";

import { meSchema } from "../../../../../lib";
import { oauthRefreshSchema } from "../../../../../types";

export const loginSchema = z.object({
  oauth: oauthRefreshSchema,
  user: meSchema,
});

export type Login = z.TypeOf<typeof loginSchema>;
