import { differenceInHours } from "date-fns";

import type { Cart } from "../types";
import { isFamilyPlan } from "./subscription";

export const dueToday = (cart: Cart) => {
  const trialDaysNum = trialDays(cart);

  // If we have an intro offer and trial, today you only pay
  // for the intro offer
  if (trialDaysNum > 0 && cart.intro_offer) {
    return cart.intro_offer?.amount;
  }

  // If its a family plan, you will always be paying proration
  if (isFamilyPlan("product", cart.product) && cart.discount > 0) {
    return cart.total;
  }

  // If no trial, today the cart total is due
  if (trialDaysNum <= 0) {
    return cart.total;
  }

  // Otherwise nothing is due right now
  return 0;
};

export const grant = (
  cart: Cart,
  name: string,
  filter: (item: any) => boolean = () => true,
): any | undefined => {
  return cart.promo_code?.grants.find(
    (grant) => grant.name === name && filter(grant.variables),
  )?.variables;
};

export const rule = (cart: Cart, name: string): any | undefined => {
  return cart.promo_code?.rules.find((rule) => rule.name === name)?.variables;
};

export const onlyNewUsers = (cart: Cart) => {
  return !!rule(cart, "had_previous_trial_rule");
};

export const trialDays = (cart: Cart): number => {
  // If we have a grant, use that otherwise calculate
  return (
    grant(cart, "trial_days_grant")?.trial_days ??
    Math.round(
      differenceInHours(
        new Date(cart.trial_end_date) || new Date(),
        new Date(),
      ) / 24,
    ) | 0
  );
};

export const discountPercent = (cart: Cart): number => {
  return parseInt(
    grant(cart, "discount_grant", (item) => item.type === "percent")?.amount ??
      0,
  );
};

export const hasTrialDaysGrant = (cart: Cart): boolean => {
  return !!grant(cart, "trial_days_grant");
};

export const hasSpecifiedEndDate = (cart: Cart): boolean => {
  return !!grant(cart, "trial_end_date_grant")?.end_date;
};
