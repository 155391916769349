"use client";

import { useRequestMutation } from "../../../request";

type EmailVerify = {
  user: string;
  email: string;
  expiration: string;
  signature: string;
};

export const useRequestVerify = () => ({
  verifyEmail: useRequestMutation({
    mutationFnRequestProps: (data: EmailVerify) => ({
      body: JSON.stringify(data),
      method: "POST",
      urlBackendPath: "/verify/email",
      requestType: "default",
    }),
  }),
});
