"use client";

import {
  LOCAL_STORAGE_KEY_HLW_FONT_STYLE,
  LOCAL_STORAGE_KEY_HLW_LETTER_SPACING,
  LOCAL_STORAGE_KEY_HLW_WORD_SPACING,
  LOCAL_STORAGE_KEY_PREFERRED_THEME,
  useLocalStorageState,
} from "../lib";

export const useAccessibility = () => {
  const [hlwFontStyle] = useLocalStorageState({
    defaultValue: "normal",
    key: LOCAL_STORAGE_KEY_HLW_FONT_STYLE,
  });
  const [hlwLetterSpacing] = useLocalStorageState<boolean>({
    defaultValue: false,
    key: LOCAL_STORAGE_KEY_HLW_LETTER_SPACING,
  });
  const [preferredTheme] = useLocalStorageState({
    defaultValue: "match",
    key: LOCAL_STORAGE_KEY_PREFERRED_THEME,
  });
  const [hlwWordSpacing] = useLocalStorageState<boolean>({
    defaultValue: false,
    key: LOCAL_STORAGE_KEY_HLW_WORD_SPACING,
  });

  return { hlwFontStyle, hlwLetterSpacing, preferredTheme, hlwWordSpacing };
};
