import { articleSchema, menusSchema } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestWordpressProps = Partial<Omit<RequestProps, "schema">> & {
  locale: string;
};

export const requestWordpressMenus = ({ locale }: RequestWordpressProps) => {
  const signal = AbortSignal.timeout(10000);
  signal.throwIfAborted();
  return request({
    next: { tags: [`menus`], revalidate: 60 * 60 },
    requestType: "default",
    schema: menusSchema,
    url: `${process.env.NEXT_PUBLIC_WORDPRESS_API}/menus?locale=${locale}`,
    signal,
  });
};

export const requestWordpressArticles = ({ locale }: RequestWordpressProps) => {
  const signal = AbortSignal.timeout(10000);
  signal.throwIfAborted();
  return request({
    next: { tags: [`articles`], revalidate: 60 * 60 },
    requestType: "default",
    schema: articleSchema.array(),
    url: `${process.env.NEXT_PUBLIC_WORDPRESS_API}/posts/latest?locale=${locale}`,
    signal,
  });
};
