import { z } from "zod";

import { prayerQueueItemSchema, radioQueueItemSchema } from "./queueItemSchema";

const managedExtension = {
  uuid: z.string(),
  errored: z.boolean(),
};

const queueManagedRadioItem = radioQueueItemSchema.extend(managedExtension);
const queueManagedPrayerItem = prayerQueueItemSchema.extend(managedExtension);

export type QueueManagedRadioItem = z.infer<typeof queueManagedRadioItem>;
export type QueueManagedPrayerItem = z.infer<typeof queueManagedPrayerItem>;
export type QueueManagedItem = QueueManagedPrayerItem | QueueManagedRadioItem;
