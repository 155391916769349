import { referralsSchema } from "../../../../lib";
import { useRequestQuery } from "../../../../request";
import { keyReferrals } from "./keyReferrals";

export const useRequestReferrals = () => ({
  query: useRequestQuery({
    enabledWithoutSessionToken: false,
    queryKey: keyReferrals(),
    queryFnRequestProps: {
      schema: referralsSchema,
      urlBackendPath: "/me/referrals",
    },
  }),
});
