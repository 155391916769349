import Cookies from "js-cookie";
import type {
  RequestCookies,
  ResponseCookies,
} from "next/dist/compiled/@edge-runtime/cookies";
import type { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";

import { COOKIE_KEY_HLW_CONSENT } from "../cookieHlwConsent.cookie";
import type { CookieKey } from "../types";
import { deleteCookie } from "./deleteCookie";
export type SetCookieProps = {
  cookies?: ReadonlyRequestCookies | RequestCookies | ResponseCookies;
  key: CookieKey;
  value: any;
  domain?: string;
  options?: Record<string, any>;
};

export const setCookie = ({
  cookies,
  key,
  value,
  domain = ".hallow.com",
  options = {},
}: SetCookieProps) => {
  if (value === null) {
    deleteCookie({ cookies, key });
    return;
  }

  const cookieOptions = {
    domain,
    path: "/",
    ...options,
  };

  /** TODO: remove (Access cannot read JSON.stringify() value...) */
  if (key === COOKIE_KEY_HLW_CONSENT)
    return Cookies.withConverter({
      write: function (value) {
        return `[${value}]`;
      },
    }).set(key, value, cookieOptions);

  const parsedValue = typeof value === "string" ? value : JSON.stringify(value);

  if (cookies) {
    cookies.set(key, parsedValue, cookieOptions);
  } else {
    Cookies.set(key, parsedValue, cookieOptions);
  }
};
