"use client";

import type { CommunityActivityFeedReferenceType } from "../../../../lib";
import {
  type CommunityActivityFeed,
  communityActivityFeedSchema,
} from "../../../../lib/types/schema/community/activityFeed";
import type { UseRequestInfiniteQueryProps } from "../../../../request";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunityActivityFeed } from "./keyCommunityActivityFeed";

export type UseRequestCommunityActivityFeedProps =
  UseRequestInfiniteQueryProps & {
    id: number;
    type?: CommunityActivityFeedReferenceType;
  };

export const useRequestCommunityActivityFeed = ({
  id,
  type,
  ...props
}: UseRequestCommunityActivityFeedProps) => ({
  infiniteQuery: useRequestInfiniteQuery({
    getNextPageParam: (lastPage: CommunityActivityFeed) =>
      lastPage?.results[lastPage?.results?.length - 1]?.id || null,
    initialPageParam: -1,
    ...props,
    queryFnRequestProps: ({ pageParam }) => {
      const searchParams = new URLSearchParams("?per_page=10");
      if (pageParam && pageParam !== -1)
        searchParams.set("last_id", pageParam as string);
      if (type) searchParams.set("post_types[]", type);

      return {
        schema: communityActivityFeedSchema,
        urlBackendPath: `/me/communities/${id}/activity-feed${searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ""}`,
      };
    },
    queryKey: keyCommunityActivityFeed({ id, type }),
  }),
});
