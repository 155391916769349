import { z } from "zod";

export const ipSchema = z.object({
  continent: z.string(),
  continent_code: z.string(),
  country: z.string(),
  country_code: z.string(),
  region: z.string(),
});

export type IP = z.TypeOf<typeof ipSchema>;
