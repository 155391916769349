"use client";

import type { UseRequestMutationProps } from "../../../request";
import { useRequestMutation } from "../../../request";
import { ipSchema } from "./types";

export type UseRequestIpProps = UseRequestMutationProps;

export const useRequestIp = ({ ...props }: UseRequestIpProps = {}) => ({
  mutationGet: useRequestMutation({
    ...props,
    mutationFnRequestProps: () => ({
      method: "GET",
      requestType: "other",
      schema: ipSchema,
      url: `https://ipwhois.pro/json/?key=${process.env.NEXT_PUBLIC_WHOIS_KEY}`,
    }),
  }),
});
