import { z } from "zod";

import { playlistContentSchema } from "./playlistContentResponseSchema";

export const playlistContentPreviewSchema = playlistContentSchema.extend({
  content_id: z.number().nullable(),
  responder_summary: z.object({
    image_urls: z.array(z.any()),
    label: z.string(),
  }),
});

export type PlaylistContentPreview = z.infer<
  typeof playlistContentPreviewSchema
>;
