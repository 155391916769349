"use client";

import {
  communitiesAdminStatsSchema,
  dateFormatYYYYMMDD,
} from "../../../../../../lib";
import type { UseRequestQueryProps } from "../../../../../../request";
import { useRequestQuery } from "../../../../../../request";
import { keyCommunitiesStatsTotalsOverTime } from "./keyCommunitiesStatsTotalsOverTime";

export type UseRequestCommunitiesStatsTotalsOverTimeProps =
  UseRequestQueryProps & {
    endDate?: Date;
    id: number;
    startDate?: Date;
  };

export const useRequestCommunitiesStatsTotalsOverTimeProps = ({
  endDate,
  id,
  startDate,
  ...props
}: UseRequestCommunitiesStatsTotalsOverTimeProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: communitiesAdminStatsSchema,
      urlBackendPath: `/communities/${id}/stats/totals-over-time?${startDate ? `start_date=${dateFormatYYYYMMDD({ date: startDate })}` : ""}${endDate ? `&end_date=${dateFormatYYYYMMDD({ date: endDate })}` : ""}`,
    },
    queryKey: keyCommunitiesStatsTotalsOverTime({ endDate, id, startDate }),
  }),
});
